<!-- 文件上传 -->

<template>
  <div class="file-upload">
    <ul>
      <li @click="uploadStart" v-loading="isUploading" element-loading-background="rgba(0,0,0,0.3)">
        <i class="iconfont icon-a-lianhe4"></i>
      </li>
    </ul>
    <div v-for="(item, index) in fileLists" :key="index" class="flex-btw file-style">
      <div class="text-hidden">{{item.name}}</div>
      <span class="cursor-pointer" @click="handleRemove(item)">
        <el-icon>
          <Close />
        </el-icon>
      </span>
    </div>
    <!-- 上传组件 -->
    <el-upload ref="uploader" action="" :on-exceed="onExceed" :accept="accept" multiple :limit="limit"
      :show-file-list="false" :file-list="fileLists" :http-request="customUpload">
    </el-upload>
  </div>
</template>
<script setup>
  import { ref, computed } from "vue";
  import { Close } from "@element-plus/icons-vue";
  // 导入七牛云上传方法
  import * as qiniu from "qiniu-js";
  import { useStore } from "vuex";
  import { ElMessage } from "element-plus";

  const props = defineProps({
    // 允许上传文件的最大数量
    limit: {
      type: Number,
      default: 1,
    },
    // 标题
    uploadTitle: {
      type: String,
      default: "文件",
    },
    // 上传文件数据
    fileLists: {
      type: Array,
      default() {
        return [];
      },
    },
    accept: {
      type: String,
      default: "",
    }
  });
  const emits = defineEmits(["uploadFile", "deleteFile", "uploadLoading"]);
  const store = useStore();
  const qiniuData = computed(() => store.state.Config.qiniuData);  // 七牛云上传参数
  const uploader = ref(null);  // 上传对象
  /**
   * 
   * 是否正在上传
   * 
   * */
  const isUploading = ref(false);
  /**
   * 
   * 点击上传图标处理
   * 
   * */
  const uploadStart = () => {
    if (!isUploading.value) {
      uploader.value.$el.children[0].children[0].click();
    }
  };
  /**
   * 
   * 自定义上传
   * 
   * */
  const customUpload = (params) => {
    isUploading.value = true;
    emits("uploadLoading", true);
    const file = params.file;
    const token = qiniuData.value.token;
    const bucket = "cultural";
    const date = new Date();
    const nowDay =
      date.getFullYear() + "" + (date.getMonth() + 1) + "" + date.getDate();
    const filename = file.name;
    const index = filename.lastIndexOf(".");
    const suffix = filename.substr(index);
    const random = Math.floor(Math.random() * (1 - 1000) + 1000); //1-1000随机数
    const randoms = Math.floor(Math.random() * (1 - 100) + 100); //1-100随机数
    const key = `${bucket}/${nowDay}/${new Date().getTime()}${random}-${randoms}${suffix}`;
    const putExtra = { fname: filename };
    const config = { useCdnDomain: true, region: qiniu.region.z2 };

    const observable = qiniu.upload(file, key, token, putExtra, config);
    // 上传开始
    observable.subscribe({
      next() { },
      error() { },
      complete(res) {
        let data = {
          name: file.name,
          key: res.key,
          size: res.fsize,
          hash: res.hash,
        };
        emits("uploadFile", data);
        isUploading.value = false;
      },
    });
  };
  /**
   * 
   * 上传最大数量提示
   * 
   * */
  const onExceed = () => {
    ElMessage.warning(`只允许上传${props.limit}个${props.uploadTitle}！`);
  };
  /**
   * 
   * 文件删除处理
   * 
   * */
  const handleRemove = (obj) => {
    emits("deleteFile", obj);
  };
</script>

<style lang="scss">
  .file-upload {
    >ul {
      li {
        display: inline-block;
        width: 56px;
        height: 56px;
        margin-right: 8px;
        margin-bottom: 10px;
        vertical-align: middle;
        cursor: pointer;
        position: relative;
      }

      li:first-child {
        border-radius: 3px;
        background-color: var(--search-uncheck-bg-color);
        line-height: 56px;
        text-align: center;
        position: relative;

        >i {
          color: #b3b3b3;
          font-size: 14px;
        }
      }

      li:first-child:hover {
        background-color: var(--search-bg-color);

        >i {
          color: var(--theme-color);
        }
      }

      li:hover .del {
        display: block;
      }
    }


    .el-upload {
      display: none;
    }

    .el-loading-spinner {
      top: 58%;

      .circular {
        width: 30px;
        height: 30px;
      }
    }

    .file-style {
      margin: 10px 0;
      padding: 0 10px;
      border: 2px dashed #eee;
    }

    .del {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      height: 13px;
      width: 18px;
      line-height: 13px;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 0 4px 0 4px;
      text-align: center;
      font-size: 12px;
    }
  }
</style>